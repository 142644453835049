<template>
  <div class="resourceDetail">
    <van-nav-bar
      title="资源跟踪详情"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="rescueDetailcon">
      <div class="top">
        <div class="topleft">
          <img
            :src="require('../../assets/images/' + form.status + 'zy.png')"
            alt=""
            class="topimg"
          />
          <span class="status">{{ statusList[form.status] }}</span>
          <img src="../../assets/images/arrow-left.png" alt="" class="arrow" />
        </div>
        <div class="topright">
          <span class="label">{{ timeLabel[form.status] }}</span>
          <span :class="isOverTime ? 'overtime' : 'time'">{{ endTime }}</span>
        </div>
      </div>
      <div class="body">
        <div class="block">
          <div class="blocktitle">{{ form.code || "--" }}</div>
          <div class="blocktop">
            <div class="blockitem">
              <div class="blockitemlabel">物资名称：</div>
              <div class="blockitemvalue">
                {{ form.content.materialName || "--" }}
              </div>
            </div>
            <div class="blockitem">
              <div class="blockitemlabel">物资类型：</div>
              <div class="blockitemvalue">
                {{ form.content.materialTypeName || "--" }}
              </div>
            </div>
            <div class="blockitem">
              <div class="blockitemlabel">规格型号：</div>
              <div class="blockitemvalue">
                {{ form.content.materialModel || "--" }}
              </div>
            </div>
            <div class="blockitem">
              <div class="blockitemlabel">物资数量：</div>
              <div class="blockitemvalue">
                {{ form.content.materialNum || "--" }}
              </div>
            </div>
            <div class="blockitem">
              <div class="blockitemlabel">负责人：</div>
              <div class="blockitemvalue">
                {{ form.content.chargePerson || "--" }}
              </div>
            </div>
            <div class="blockitem">
              <div class="blockitemlabel">联系方式：</div>
              <div class="blockitemvalue">
                {{ form.content.chargePersonTel || "--" }}
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="blocktitle">主要职责</div>
          <div class="content">
            {{ form.contentStr || "--" }}
          </div>
        </div>
        <div class="block">
          <div class="blocktitle">事故信息</div>
          <div class="middle">
            <div class="left">
              <div class="label">事发距离</div>
              <div class="value">{{ transferDistance(form.distance) }}</div>
            </div>
            <div class="shu"></div>
            <div class="right">
              <div class="label">事发等级</div>
              <div class="value">{{ transferLevel(form.accident.level) }}</div>
            </div>
          </div>
          <div class="blockbottom">
            <div class="blockbottomitem">
              <div class="blockbottomitemlabel">事故类型：</div>
              <div class="blockbottomitemvalue">{{ accidenttype }}</div>
            </div>
            <div class="blockbottomitem">
              <div class="blockbottomitemlabel">事发地点：</div>
              <div class="blockbottomitemvalue">
                {{ form.accident.place || "--" }}
              </div>
            </div>
            <div class="blockbottomitem">
              <div class="blockbottomitemlabel">上报时间：</div>
              <div class="blockbottomitemvalue">
                {{ form.accident.subTime || "--" }}
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="blocktitle">任务信息</div>
          <div class="blocktop">
            <div class="blockitem">
              <div class="blockitemlabel">任务来源：</div>
              <div class="blockitemvalue">
                {{ form.source == 1 ? "领导下发" : "预案下发" }}
              </div>
            </div>
            <div class="blockitem">
              <div class="blockitemlabel">领导姓名：</div>
              <div class="blockitemvalue">
                {{ form.content.chargePerson || "--" }}
              </div>
            </div>
            <div class="blockitem">
              <div class="blockitemlabel">下发时间：</div>
              <div class="blockitemvalue">{{ form.finishTime || "--" }}</div>
            </div>
          </div>
          <div class="plancon">
            <div class="planconlabel">预案信息：</div>
            <div class="planbody">
              <div class="planbodyitem">
                <div class="planbodyitemleft">
                  <div class="diot"></div>
                  <span class="planbodyitemname">预案名称</span>
                </div>
                <div class="planbodyitemvalue">{{ planInfo.name }}</div>
              </div>
              <div class="planbodyitem">
                <div class="planbodyitemleft">
                  <div class="diot"></div>
                  <span class="planbodyitemname">响应等级</span>
                </div>
                <div class="planbodyitemvalue">
                  {{ planInfo.planLevelName }}
                </div>
              </div>
              <div class="planbodyitem">
                <div class="planbodyitemleft">
                  <div class="diot"></div>
                  <span class="planbodyitemname">事故类型</span>
                </div>
                <div class="planbodyitemvalue">
                  {{ subStr(planInfo.accidentTypeName) }}
                </div>
              </div>
              <div
                v-for="(item, index) in planList"
                :key="index"
                class="planload"
              >
                <div class="plan">
                  <img src="../../assets/images/fj.png" alt="" class="fj" />
                  <span>{{ item.name || "--" }}</span>
                </div>
                <div class="load" @click="download(item.fileId)">
                  点击下载
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="form.status == 1 || form.status == 2 || form.status == 3"
      class="btnbottom"
    >
      <div class="btn cancel" @click="updateStatus(4)">取消任务</div>
      <div class="btn confirm" @click="updateStatus">
        {{ btnLabel[form.status] }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "./api";
import { Dialog } from "vant";
export default {
  name: "ResourceDetail",
  data() {
    return {
      form: {},
      timer: null,
      endTime: "",
      isOverTime: false,
      accidenttype: "",
      isShowAll: false,
      fromClick: false,
      planList: [],
      userList: [],
      planInfo: {},
      statusList: {
        1: "待接收",
        2: "运输中",
        4: "已取消",
        5: "已到位"
      },
      timeLabel: {
        1: "剩余",
        2: "已进行",
        4: "",
        5: "共花费"
      },
      btnLabel: {
        1: "开始执行",
        2: "物资到位",
        4: "",
        5: ""
      }
    };
  },
  created() {
    this.form = JSON.parse(decodeURIComponent(this.$route.params.formData));
    this.timeHandle();
    this.getDictList();
    this.getPlanDetail(this.form.accident.planId);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    subStr(str) {
      if (str) {
        return str.slice(0, str.length - 1);
      } else {
        return "--";
      }
    },
    onClickLeft() {
      history.go(-1);
    },
    updateTaskTrace(data) {
      api.updateTaskTrace(data).then(res => {
        this.form.status = data[0].status;
        this.timeHandle();
        if (this.supportLocation) {
          Dialog.alert({
            title: "",
            message: `操作成功！`
          });
        } else {
          Dialog.alert({
            title: "操作成功！",
            message: `当前设备未开启定位请求，指挥部无法获取当前定位，请前往设置开启定位服务！`
          });
        }
      });
    },
    //更新任务状态
    updateStatus(data) {
      let that = this;
      if (!window.android || !window.android.getLocation) {
        this.supportLocation = false;
        let query = {
          id: this.form.id,
          status: this.form.status,
          mesStatus: 1
        };
        if (data == 4) {
          query.status = data;
        } else if (this.form.status == 2) {
          query.status = 5;
          that.fromClick = true;
        } else {
          query.status = parseInt(this.form.status) + 1;
        }
        that.updateTaskTrace([query]);
      } else {
        this.supportLocation = true;
        window.localGeoCallBack = async (location, time, status) => {
          if (status === "1") {
            let localArr = location.split(",");
            let query = {
              id: this.form.id,
              status: this.form.status,
              mesStatus: 1,
              realGeo: {
                request2d: "POINT(" + localArr[0] + " " + localArr[1] + ")"
              }
            };
            if (data == 4) {
              query.status = data;
            } else if (this.form.status == 2) {
              query.status = 5;
              this.fromClick = true;
            } else {
              query.status = parseInt(this.form.status) + 1;
            }
            await that.updateTaskTrace([query]);
          }
        };
        window.androidH5.getLocation("localGeoCallBack");
      }
    },
    //下载附件
    download(data) {
      window.location.href = `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=true&fileName=${data}`;
    },
    //获取预案详情
    getPlanDetail(data) {
      if (data) {
        api.getPlanDetail(data).then(res => {
          this.planInfo = res;
          this.planList = res.attachments || [];
        });
      } else {
        this.planInfo = {
          name: "--",
          planLevelName: "--",
          accidentTypeName: "--"
        };
      }
    },
    transationTime(subTime) {
      // 终止条件 剩余时间小于1分钟
      let day = Math.floor(subTime / (1000 * 60 * 60 * 24));
      day = day < 10 ? "0" + day : day;
      subTime = subTime % (1000 * 60 * 60 * 24);
      let hour = Math.floor(subTime / (1000 * 60 * 60));
      hour = hour < 10 ? "0" + hour : hour;
      subTime = subTime % (1000 * 60 * 60);
      let minute = Math.floor(subTime / (1000 * 60));
      minute = minute < 10 ? "0" + minute : minute;
      subTime = subTime % (1000 * 60);
      let second = Math.floor(subTime / 1000);
      second = second < 10 ? "0" + second : second;
      this.endTime = day + "天" + hour + "：" + minute + "：" + second;
    },
    // 时间处理
    timeHandle() {
      if (this.timer) clearInterval(this.timer);
      let startTime = new Date(this.form.issueTime).getTime();
      let nowData = new Date().getTime();
      if (this.form.status == 1) {
        //计算剩余时间
        let sub = nowData - startTime;
        let fifteenMinute = 15 * 60 * 1000;
        if (fifteenMinute > sub) {
          let subTime = fifteenMinute - sub;
          this.transationTime(subTime);
          this.timer = setInterval(() => {
            subTime = subTime - 1000;
            if (subTime <= 0) {
              this.endTime = "已超时";
              this.isOverTime = true;
              clearInterval(this.timer);
            } else {
              this.transationTime(subTime);
            }
          }, 1000);
        } else {
          this.endTime = "已超时";
          this.isOverTime = true;
        }
      } else if (this.form.status == 2 || this.form.status == 3) {
        //计算已进行的时间
        this.isOverTime = false;
        let sub = nowData - startTime;
        this.transationTime(sub);
        this.timer = setInterval(() => {
          sub = sub + 1000;
          this.transationTime(sub);
        }, 1000);
      } else if (this.form.status == 5) {
        //计算共花费的时间
        let doneTime = "";
        if (this.fromClick) {
          doneTime = new Date().getTime();
        } else {
          doneTime = new Date(this.form.finishTime).getTime();
        }
        this.isOverTime = false;
        let sub = doneTime - startTime;
        this.transationTime(sub);
      } else if (this.form.status == 4) {
        //已取消时，
        this.endTime = "";
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }
    },
    //距离格式化
    transferDistance(data) {
      if (data) {
        let distance = parseInt(data);
        return (distance / 1000).toFixed(1) + "km";
      } else {
        return "--";
      }
    },
    // 获取字典值
    getDictList() {
      api.getShelterTypes({ types: "planAccidentType" }).then(data => {
        if (data.planAccidentType && data.planAccidentType.length > 0) {
          this.accidenttype = data.planAccidentType.filter(
            item => item.value == this.form.accident.accidentType
          )[0].label;
        }
      });
    },
    //事故等级格式化
    transferLevel(data) {
      if (data) {
        if (data == 1) {
          return "Ⅰ级";
        } else if (data == 2) {
          return "Ⅱ级";
        } else if (data == 3) {
          return "Ⅲ级";
        } else if (data == 4) {
          return "Ⅳ级";
        }
      } else {
        return "--";
      }
    }
  }
};
</script>

<style lang="scss">
.resourceDetail {
  div {
    box-sizing: border-box;
  }
  font-family: PingFangSC-Regular, PingFang SC;
  .van-nav-bar {
    background: linear-gradient(118deg, #62a6f8 0%, #1677ff 100%);
  }
  .rescueDetailcon {
    .top {
      display: flex;
      justify-content: space-between;
      padding: 14px;
      padding-bottom: 20px;
      background: linear-gradient(118deg, #62a6f8 0%, #1677ff 100%);
      .topleft {
        display: flex;
        align-items: center;

        .topimg {
          width: 14px;
          margin-right: 10px;
        }
        .status {
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          margin-right: 6px;
        }
        .arrow {
          width: 6px;
        }
      }
      .topright {
        display: flex;
        align-items: center;
      }
      .label {
        font-size: 14px;
        color: #ffffff;
        margin-right: 6px;
      }
      .time {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
      }
      .overtime {
        font-size: 18px;
        font-weight: 600;
        color: red;
      }
    }
    .body {
      margin-top: -8px;
      background: #f7f8fa;
      box-shadow: 0px 4px 8px 0px rgba(177, 200, 243, 0.15);
      border-radius: 8px 8px 0px 0px;
      height: calc(100vh - 102px);
      overflow: auto;
      padding: 14px 10px 70px;
      .block {
        background: #fff;
        padding: 8px 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        .name {
          font-size: 16px;
          color: #3b4664;
          line-height: 22px;
          font-weight: 500;
        }
        .middle {
          display: flex;
          padding: 16px 0 9px 10px;
          border-bottom: 1px solid #f1f1f1;
          .left {
            width: 50%;
            .label {
              font-size: 12px;
              color: #3b4664;
              margin-bottom: 6px;
            }
            .value {
              font-size: 18px;
              color: #1f78ff;
              font-weight: 500;
            }
          }
          .shu {
            width: 1px;
            height: 26px;
            border: 1px solid #f1f1f1;
          }
          .right {
            width: 50%;
            padding-left: 18px;
            .label {
              font-size: 12px;
              color: #3b4664;
              margin-bottom: 6px;
            }
            .value {
              font-size: 18px;
              color: #1f78ff;
              font-weight: 500;
            }
          }
        }
        .blockbottom {
          padding-top: 10px;
          padding-left: 8px;
          line-height: 24px;
          .blockbottomitem {
            display: flex;
            .blockbottomitemlabel {
              color: #3b4664;
              font-size: 12px;
            }
            .blockbottomitemvalue {
              font-size: 12px;
              color: #3b4664;
            }
          }
        }
        .blocktitle {
          font-size: 16px;
          color: #3b4664;
          font-weight: 500;
        }
        .content {
          line-height: 18px;
          font-size: 12px;
          padding: 10px;
          padding-bottom: 4px;
        }
        .list {
          margin-top: 14px;
          .listitem {
            height: 30px;
            background: #f7f8fa;
            border-radius: 2px;
            display: flex;
            justify-content: space-between;
            padding: 0 12px;
            margin-bottom: 4px;
            .left {
              display: flex;
              align-items: center;
              .name {
                font-size: 12px;
                color: #3b4664;
                margin-right: 8px;
              }
              .position {
                font-size: 12px;
                color: #1f78ff;
                border: 1px solid #1f78ff;
                border-radius: 8px;
                background: rgba(1, 121, 255, 0.1);
                padding: 0 3px;
              }
            }
            .right {
              display: flex;
              align-items: center;
              .phone {
                font-size: 12px;
                color: #1f78ff;
              }
              .phoneimg {
                width: 10px;
                height: 10px;
                margin-left: 8px;
              }
            }
          }
        }
        .blocktop {
          line-height: 20px;
          padding: 10px 0;
          .blockitem {
            display: flex;
            .blockitemlabel {
              font-size: 12px;
              color: #3b4664;
              opacity: 0.7;
            }
            .blockitemvalue {
              font-size: 12px;
              color: #3b4664;
            }
          }
        }
        .plancon {
          .planconlabel {
            font-size: 12px;
            color: #3b4664;
            opacity: 0.7;
            margin-bottom: 10px;
          }
          .planbody {
            border-radius: 8px;
            background: rgba(1, 121, 255, 0.05);
            padding: 10px;
            line-height: 22px;
            .planbodyitem {
              display: flex;
              justify-content: space-between;
              .planbodyitemleft {
                display: flex;
                align-items: center;
                .diot {
                  width: 3px;
                  height: 3px;
                  background: #3e8dfe;
                  box-shadow: 0px 2px 4px 0px rgba(62, 126, 245, 0.3);
                  border-radius: 50%;
                  margin-right: 4px;
                }
                .planbodyitemname {
                  font-size: 12px;
                  color: #3b4664;
                  opacity: 0.7;
                }
              }
              .planbodyitemvalue {
                font-size: 12px;
                color: #3b4664;
              }
            }
            .planload {
              background: #ffffff;
              border-radius: 8px;
              padding: 12px 6px;
              padding-top: 18px;
              margin-top: 6px;
              .plan {
                height: 36px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                background: linear-gradient(
                  180deg,
                  #ffffff 0%,
                  rgba(237, 243, 255, 0.8) 100%
                );
                .fj {
                  width: 12px;
                  margin-right: 8px;
                }
                span {
                  font-size: 12px;
                  color: #1677ff;
                }
              }
              .load {
                color: #1677ff;
                font-size: 12px;
                text-align: center;
                padding-top: 10px;
              }
            }
          }
        }
        .btnitem {
          text-align: center;
          font-size: 12px;
          color: #1f78ff;
          padding: 14px 0;
        }
      }
    }
  }
  .btnbottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 50px;
    background: #fff;
    padding: 5px 16px;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 165px;
      height: 40px;
      border-radius: 100px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
    }
    .cancel {
      border: 1px solid #dcdee0;
      background: #ffffff;
      color: #323233;
    }
    .confirm {
      color: #ffffff;
      background: linear-gradient(180deg, #1677ff 0%, #62a6f8 100%);
    }
  }
}
</style>
